<template>
    <footer id="page-footer" class="bg-gray-50 dark:bg-[#161b1e]">
        <div v-if="isFooterVisible === true"
            class="container mx-auto max-w-[1045px] px-6 xl:px-0 pt-16 pb-4 flex flex-col gap-32">
            <div class="md:flex md:justify-between">
                <div class="mb-6 md:mb-0">
                    <a href="/" class="flex items-center">
                        <img src="/logo.svg" class="h-8" alt="Simply Wafa Logo" width="132" height="32" />
                    </a>
                </div>
                <div class="grid grid-cols-1 sm:grid-cols-3 gap-12">
                    <div>
                        <p class="mb-3 text-xs font-semibold text-gray-900 dark:text-gray-300 uppercase">Simply Wafa
                        </p>
                        <ul>
                            <li><a class="text-xs hover:underline text-gray-500" href="/about">À
                                    propos</a></li>
                            <li><a class="text-xs hover:underline text-gray-500"
                                    href="mailto:hello@simply-wafa.com?subject=Commentaire sur votre merveilleux blog de cuisine">Contact</a>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <p class="mb-3 text-xs font-semibold text-gray-900 dark:text-gray-300 uppercase">Suivez-nous
                        </p>
                        <ul>
                            <li><a class="text-xs hover:underline text-gray-500"
                                    href="https://www.facebook.com/profile.php?id=61553884538081" target="_blank"
                                    rel="noopener noreferrer">Facebook</a></li>
                            <li><a class="text-xs hover:underline text-gray-500" href="https://twitter.com/_simplywafa"
                                    target="_blank" rel="noopener noreferrer">X
                                    (Twitter)</a></li>
                            <li><a class="text-xs hover:underline text-gray-500"
                                    href="https://www.instagram.com/_simplywafa/" target="_blank"
                                    rel="noopener noreferrer">Instagram</a></li>
                            <li><a class="text-xs hover:underline text-gray-500"
                                    href="https://www.youtube.com/channel/UCB1KsLv3i2GnCQTsUSl5apg" target="_blank"
                                    rel="noopener noreferrer">YouTube</a></li>
                        </ul>
                    </div>

                    <div>
                        <p class="mb-3 text-xs font-semibold text-gray-900 dark:text-gray-300 uppercase">Légal</p>
                        <ul>
                            <li><a href="/legal/terms" class="text-xs hover:underline text-gray-500"
                                    target="_blank">CGU</a>
                            </li>
                            <li><a href="/legal/privacy" class="text-xs hover:underline text-gray-500"
                                    target="_blank">Vie
                                    privée</a></li>
                            <li><a href="/legal/notice" class="text-xs hover:underline text-gray-500"
                                    target="_blank">Mentions
                                    légales</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="flex flex-col gap-4">
                <hr class="border-gray-200 dark:border-gray-600" />
                <span class="text-xs text-gray-500">© 2024 <a href="/" class="hover:underline dark:text-gray-300">Simply
                        Wafa</a>. Tous droits réservés.
                </span>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { ref, onMounted } from 'vue';

let isFooterVisible = ref(false);

onMounted(() => {
    if (process.client) {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    isFooterVisible.value = true;

                    // Stop observing once the section is loaded
                    observer.unobserve(entry.target);
                }
            });
        }, {
            // Optional configuration:
            root: null, // Use the viewport as the root
            rootMargin: '0px', // No margin
            threshold: 0.1, // Trigger when 10% of the section is visible
        });

        // Get the section you want to observe
        const element = document.getElementById('page-footer');

        // Start observing the section
        observer.observe(element);
    }
})
</script>