<template>
    <nav
        class="border-b-2 md:border-y-0 border-gray-200 dark:border-none bg-white dark:bg-fill-secondary md:bg-transparent md:dark:bg-transparent">
        <div class="block md:flex md:justify-center md:items-center md:mt-8">
            <div class="max-w-screen-xl flex flex-wrap items-center justify-between md:justify-center mx-auto p-4">
                <a href="/" class="w-40 md:w-max">
                    <img src="/logo.svg" alt="Simply Wafa Logo" width="260" height="60">
                </a>
                <div class="block md:hidden">
                    <button data-collapse-toggle="menu" type="button"
                        class="inline-flex items-center justify-center p-2 w-10 h-10 text-sm text-gray-500 dark:text-gray-400 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-600"
                        aria-controls="navbar-hamburger" aria-expanded="false" @click="toggleMobileMenu">
                        <span class="sr-only">Ouvrir le menu principal</span>
                        <div id="lottie-container"></div>
                    </button>
                </div>
                <div class="w-full transition-all ease-in duration-500 hidden md:block" id="menu" ref="menu">
                    <ul class="flex flex-col md:flex-row md:justify-center font-medium my-4 rounded-lg gap-0 md:gap-11">
                        <li>
                            <NuxtLink to="/"
                                class="text-3xl md:text-xl block py-2 text-gray-900 dark:text-gray-100 hover:text-primary-200 dark:hover:text-primary-300">
                                Recettes
                            </NuxtLink>
                        </li>
                        <li>
                            <NuxtLink to="/convertisseurs/grammes-millilitres"
                                class="text-3xl md:text-xl block py-2 text-gray-900 dark:text-gray-100 hover:text-primary-200 dark:hover:text-primary-300">
                                Conversions
                            </NuxtLink>
                        </li>
                        <li>
                            <Dropdown />
                        </li>
                        <li>
                            <NuxtLink to="/about"
                                class="text-3xl md:text-xl block py-2 text-gray-900 dark:text-gray-100 hover:text-primary-200 dark:hover:text-primary-300">
                                À propos
                            </NuxtLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup>
import { watch, onBeforeMount, onUnmounted } from 'vue';
import lottie from 'lottie-web';

// Reference to the menu element
const menu = ref(null);
// Reference to the animation
const animation = ref(null);

onBeforeMount(() => {
    animation.value = lottie.loadAnimation({
        container: document.getElementById("lottie-container"),
        renderer: 'svg',
        loop: false,
        autoplay: false,
        path: '../menuV2.json'
    });

    animation.value.setSpeed(2) // Play 2x faster

    // Clean up the animation instance when the component unmounts
    onUnmounted(() => {
        animation.value.destroy();
    });
});

// Method for toggling the mobile menu
const toggleMobileMenu = () => {
    if (menu.value.classList.contains('hidden')) {
        menu.value.classList.remove('hidden');
        menu.value.classList.add('block');
        animation.value.playSegments([0, 60], true);
    } else {
        hideMobileMenu();
    }
};

const hideMobileMenu = () => {
    menu.value.classList.add('hidden');
    menu.value.classList.remove('block');
    animation.value.playSegments([40, 0], true);
};

// Watch for route changes to hide the menu
const route = useRoute();
watch(() => route.path, () => {
    if (menu.value) {
        hideMobileMenu();
    }
});
</script>

<style scoped>
.router-link-exact-active {
    text-decoration: none;
    font-weight: bolder;
}

.router-link-exact-active {
    @apply text-black dark:text-white;
}

@media (max-width: 767px) {
    .router-link-exact-active {
        text-decoration: underline;
        font-weight: 600;
    }
}

#lottie-container {
    transform: scale(1.5);
    transform-origin: center;
}

a {
    text-decoration: none !important;
}
</style>