<template>
  <div class="relative">
    <button @click="toggleDropdown"
      class="text-3xl md:text-xl py-2 dark:text-white hover:text-primary-200 flex items-center space-x-1">
      <span>Articles</span>
      <ChevronDownIcon class="w-6 h-6" />
    </button>
    <div v-if="isOpen"
      class="origin-top-right absolute right-0 mt-2 w-max rounded-md shadow-lg bg-white dark:bg-fill-tertiary ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
      role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
      <div class="py-3" role="none">
        <template v-for="(option, index) in options" :key="option.id">
          <hr v-if="index == options.length - 1" class="my-2">
          <NuxtLink :to="option.id === 'all' ? '/articles' : `/articles/categories/${option.id}`"
            @click="toggleDropdown" class="text-lg block px-6 py-2 hover:text-primary-200 dark:text-white">
            <span :class="option.id === 'all' ? 'font-bold' : 'font-normal'">{{ option.text }}</span>
          </NuxtLink>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/16/solid'; // Ensure correct import

// Reactive state
const isOpen = ref(false);
const options = [
  { id: 'conservation', text: 'Conservation' },
  { id: 'ingredients-et-garnitures', text: 'Ingrédients et garnitures' },
  { id: 'astuces', text: 'Astuces' },
  { id: 'techniques', text: 'Techniques' },
  { id: 'accessoires', text: 'Accessoires' },
  { id: 'all', text: 'Voir tout' }
];

// Methods
const toggleDropdown = () => {
  isOpen.value = !isOpen.value;
};
</script>

<style scoped>
a {
  @apply text-black dark:text-white no-underline;
}
</style>
